import { Component, OnInit } from '@angular/core';
import { YardService } from '../services/yard.service';
import { Visitor } from '../dtos/visitor';

@Component({
  selector: 'app-visitors-list',
  templateUrl: './visitors-list.component.html',
  styleUrls: ['./visitors-list.component.css']
})
export class VisitorsListComponent implements OnInit {
  visitors: Visitor[];
  constructor(private yardService: YardService) { }

  ngOnInit() {
    this.getVistors();
  }

  getVistors() {
    this.visitors = [
      {
          "id": 4,
          "user": "test3",
          "first_name": "",
          "last_name": "",
          "email": "",
          "company": "",
          "visitor_type": "",
          "phone": "",
          "reason": "",
          "location": "",
          "comment": "",
          "start": null,
          "end": null,
          "email_content": "",
          "deleted": false,
          "created_date_time": "2024-09-13T17:21:07.461016",
          "updated_date_time": "2024-09-13T17:21:07.461016",
          "visitor_id": "70157"
      },
      {
          "id": 3,
          "user": "test2",
          "first_name": "",
          "last_name": "",
          "email": "",
          "company": "",
          "visitor_type": "",
          "phone": "",
          "reason": "",
          "location": "",
          "comment": "",
          "start": null,
          "end": null,
          "email_content": "",
          "deleted": false,
          "created_date_time": "2024-09-13T17:20:56.945379",
          "updated_date_time": "2024-09-13T17:20:56.945379",
          "visitor_id": "95671"
      },
      {
          "id": 2,
          "user": "test",
          "first_name": "",
          "last_name": "",
          "email": "",
          "company": "",
          "visitor_type": "",
          "phone": "",
          "reason": "",
          "location": "",
          "comment": "",
          "start": null,
          "end": null,
          "email_content": "",
          "deleted": false,
          "created_date_time": "2024-09-13T17:15:37.976057",
          "updated_date_time": "2024-09-13T17:15:37.976057",
          "visitor_id": null
      },
      {
          "id": 1,
          "user": "",
          "first_name": "Ali",
          "last_name": "Gharibzadeh",
          "email": "",
          "company": "",
          "visitor_type": "",
          "phone": "",
          "reason": "",
          "location": "",
          "comment": "",
          "start": null,
          "end": null,
          "email_content": "",
          "deleted": false,
          "created_date_time": "2024-09-13T17:07:17.740355",
          "updated_date_time": "2024-09-13T17:07:17.740355",
          "visitor_id": ""
      }
  ];
  }

  getMailHTML() {

  }

}
