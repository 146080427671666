import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class YardService {
  apiUrl = `${environment.yardApiUrl}`; 
  constructor(private httpClient: HttpClient) { }

  private getHeaders() {
    const username = 'admin@admin.com';
    const password = 'Woehrl4100';
    return new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`${username}:${password}`)
    });
  }
  addVisitor(data: any): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.post(this.apiUrl+'visitor/', data, { headers });
  }
}
