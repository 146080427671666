export function snackMessage(_this,message: String, css: string, backgroundColor = '#fff') {

    _this.snackbarService.add({
        msg: message,
        action: {
            text: null
        },
        customClass: css,
        timeout: 5000,
        background: backgroundColor
    });
}